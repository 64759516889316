<template>
    <div id="SingleWork" class="h-100">
        <router-link :to="toURL" class="text-decoration-none ">
            <div class="card overflow-hidden h-100">
                <img loading="lazy" :src="img" class="img-fluid" style="image-rendering: smooth;"/>

                <div class="card-body">
                    <h5 class="card-title fw-bold fs-4">{{ title }}</h5>
                    <span class="card-text content fs-6 text-muted">{{ abstract }}</span>
                </div>

                <div class="card-footer d-flex flex-wrap" v-if="tags.length > 0">
                    <!-- Ensure footer is fixed at the bottom -->
                    <div v-for="word in tags" :key="word.id" class="d-flex mb-2">
                        <TagButton :tag="word" class="mx-1"></TagButton>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import TagButton from "@/components/TagButton.vue";

export default {
    components: {
        TagButton
    },
    props: {
        toURL: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        abstract: {
            type: String,
            required: true
        },
        img: {
            type: String,
            required: true
        },
        tags: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
};
</script>

<style lang="css" scoped>
#SingleWork {
    margin: 20px;
    height: 100%;
}


.card {
    will-change: transform, box-shadow;
    border-radius: 30px;
    height: 100%;
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out, outline 0.2s ease-out;
    position: relative;
    overflow: hidden;
}

.card:hover {
    transform: scale(1.05); /* 调整 scale，减少 GPU 计算 */
    outline: 2px solid #6e6d7a; /* 用 outline 替换 border，避免 reflow */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}


.content {
    background: linear-gradient(to right, #bebcbc, #bebcbc) no-repeat left bottom;
    background-size: 0px 2px;
    transition: background-size 0.3s ease-out;
}

.card:hover .content {
    background-size: 100% 2px;
}


.card img {
    border-radius: 30px 30px 0px 0px;
    height: 200px;
    max-height: 200px;
    object-fit: cover;
}


</style>
