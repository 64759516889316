<template>
    <div id="work-content" class="mt-3">
        <div class="row d-flex flex-row justify-content-center">
            <div class="col-2 col-xxl-3 mb-md-2">
                <img loading="lazy" :src="img" class="img-fluid" />
            </div>
            <div class="col-9 col-md-12 d-flex align-items-center justify-content-center">
                <h3 class="m-0">{{title}}</h3>
            </div>
        </div>
        
        <p class="mt-2">{{content}}</p>
    </div>
</template>


<script>
        export default {
        props:{
            img: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            content: {
                type: String,
                required: false,
                default: ""
            }
        }
    }
</script>

<style lang="css" scoped>
#work-content{
    width: 100%;
    padding-inline: 10px;
}

#work-content h3{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}

#work-content p{
    text-align: center;
    color: #6e6d7a;
    font-size: 20px;
}
</style>