<template>
    <div class="mt-5 container-lg">
       <WorkHeader :title="$t('message.project.joFind.title')" date="2024/08/24"/>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-12 p-0 m-0">
                <!--标题卡片-->
                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <div class="d-flex align-items-center">
                                <div class="d-none d-md-block me-5 border border-1 rounded-5 shadow-sm p-3">
                                    <img loading="lazy" src="@/assets/worksDetail/joFind/icon.png" class="img-fluid rounded-3" style="width: 60px; height: 60px;"/>
                                </div>

                                <div>
                                    <h1 class="fw-bold">{{ $t('message.project.joFind.app_name') }}</h1>
                                    <p class="text-muted">{{ $t('message.project.joFind.title') }}</p>
                                </div>
                            </div>
                            <div class="d-none d-md-flex flex-wrap mt-3">
                                <div v-for="(word,index) in ['VUE.JS','BOOTSTRAP','MONGODB', 'NODE.JS']" :key="index" class="d-flex mb-2">
                                    <TagButton :tag="word" class="mx-1"></TagButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/jofind_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>
                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom fw-bold">{{$t('message.project.joFind.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.joFind.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>Vue3; Bootstrap</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.back_end')}}</td>
                                    <td>Node.js、Express.js; Socket.io</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.api_testing')}}</td>
                                    <td>Postman</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.database')}}</td>
                                    <td>MongoDB</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>WebStorm; VS Code;</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!--系统功能卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="text-center red-bottom fw-bold">{{$t('message.project.musicBuddy.feature.header')}}</h3>
                    <img loading="lazy" src="@/assets/worksDetail/joFind/jofindFeatures.png" class="img-fluid w-100">
                </div>
                <!--用户界面卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class=" mt-3">
                        <h3 class="red-bottom fw-bold">求职者界面</h3>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/authentication.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.login.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.login.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.jobMarket.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.jobMarket.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/jobMarket.promo.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/chat_promo.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.chat.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.chat.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.resume.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.resume.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/resume_promo.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/applications_promo.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.applicationRecord.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.applicationRecord.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.jobManage.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.jobManage.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/jobManage_promo.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/companyManage_promo.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.companyManage.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.companyManage.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.joFind.interface.applicationManage.header')}}</h3>
                            <p>{{$t('message.project.joFind.interface.applicationManage.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/joFind/applicationManage_promo.png" class="img-fluid">
                        </div>
                    </div>



                </div>
                <!--Promo卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <img loading="lazy" src="@/assets/worksDetail/joFind/jofind_promo.png" class="img-fluid w-100">
                </div>


            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import TagButton from "@/components/TagButton.vue";

export default {
    components: {
        TagButton,
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>
.red-bottom {
    width: fit-content;
    border-bottom: 3px solid #FF6666;
}

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

.comment-list {
    column-count: 2; /* 将列表分成两列 */
    column-gap: 20px; /* 列之间的间距，可以根据需要调整 */
    padding-left: 0; /* 移除左侧内边距 */
    list-style: none; /* 去除默认的列表样式 */
}

.comment-list li {
    margin-bottom: 10px; /* 每个列表项的下边距，可以根据需要调整 */
}
</style>