<template>
    <div class="container mt-2">
        <div class="row cat-card ">
            <div class="row g-0 mb-xl-0 pb-3">
                <div class="tools">
                    <div class="circle">
                        <span class="red box"></span>
                    </div>
                    <div class="circle">
                        <span class="yellow box"></span>
                    </div>
                    <div class="circle">
                        <span class="green box"></span>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-7 d-flex align-items-center">
                    <div class="d-flex flex-column text-center container px-5">
                        <h1 class="text-white fw-bold spicy-rice-regular p-0" style="font-size: 5rem">DAN TA</h1>
                        <div class="d-flex  justify-content-center align-items-center">
                            <img loading="lazy" src="@/assets/kitty/cat_avatar_4.png"
                                 class="img-fluid object-fit-cover me-3 border border-2 border-white rounded-circle p-1  d-lg-none  "
                                 style="width: 4rem">
                            <p style="font-size: 3rem">{{ $t('message.cat.name') }}</p>
                        </div>

                        <div class="row mt-2 p-0">
                            <div class="col-4 card p-3 rounded-4 p-0">
                                <p class="fs-3">{{ $t('message.cat.age') }}</p>
                                <p class="text-muted ">{{ $t('message.cat.age_title') }}</p>
                            </div>
                            <div class="col-4 card p-3 rounded-4 p-0">
                                <p class="fs-3">{{ $t('message.cat.weight') }}</p>
                                <p class="text-muted ">{{ $t('message.cat.weight_title') }}</p>
                            </div>
                            <div class="col-4 card p-3 rounded-4 p-0">
                                <p class="fs-3">{{ $t('message.cat.sex') }}</p>
                                <p class="text-muted ">{{ $t('message.cat.sex_title') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-none  d-lg-flex col-lg-6 col-xl-5 justify-content-center align-items-center py-3">
                    <img loading="lazy" src="@/assets/kitty/cat_avatar_4.png" class="img-fluid object-fit-contain border border-4 border-white rounded-circle p-2">
                </div>
            </div>
        </div>


        <div class="line_container"><div class="line my-5"></div></div>



        <Waterfall :list="kittyList" :breakpoints="breakpoints">
            <template #item="{item, url, index}">
                <div class="card" id="cat-pic" @click="openModal(item)">
                    <LazyImg :url="url" class="card-img-top"/>
                    <div class="card-footer text-body-secondary text-center ">
                        {{ item.date }}
                    </div>
                </div>
            </template>
        </Waterfall>

        <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modal">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="card" id="cat-pic" @click="openModal(item)">
                    <img loading="lazy" :src="catItem.src" class="card-img-top"/>
                    <div class="card-footer text-body-secondary text-center gochi-hand-regular">
                        {{ catItem.date }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { LazyImg, Waterfall } from 'vue-waterfall-plugin'
import 'vue-waterfall-plugin/dist/style.css'

export default {
    components: {
        Waterfall,LazyImg
    },

    data() {
        return {
            kittyList: [],
            breakpoints: {
                0: {
                    rowPerView: 1,
                },
                576: {
                    rowPerView: 2,
                },
                768: {
                    rowPerView: 3,
                },
                992: {
                    rowPerView: 4,
                },
                1200: {
                    rowPerView: 5,
                },
                1400: {
                    rowPerView: 5,
                }
            },
            catItem:{date: "", src: ""}
        };
    },
    methods: {
        openModal(item) {
            // Populate the modal data with the clicked item
            this.catItem = { ...item };
            

            // Ensure the modal is shown
            const modalElement = document.getElementById('modal');
            if (modalElement) {
                const modal = new window.bootstrap.Modal(modalElement);
                modal.show();
            }
        },
    },
    created() {
        //使用require.context动态导入图片
        const files = require.context('@/assets/kitty', false, /\.jpg$/);
        // 使用正则表达式匹配文件名中的日期部分，并将其转换为指定格式的日期字符串
        const dateRegex = /(\d{2})(\d{2})(\d{2})\.jpg/;
        files.keys().forEach(key => {
            const fileName = key.match(dateRegex);
            if (fileName) {
                const year = '20' + fileName[1];
                const month = fileName[2];
                const day = fileName[3];
                const dateString = `${year}-${month}-${day}`;

                // 添加到list数组中
                this.kittyList.unshift({
                    date: dateString,
                    src: files(key)
                });
            }
        });
    }
}
</script>

<style lang="css" scoped>
.tools {
    display: flex;
    align-items: center;
    padding: 9px;
}

.circle {
    padding: 0 4px;
}

.box {
    display: inline-block;
    align-items: center;
    width: 10px;
    height: 10px;
    padding: 1px;
    border-radius: 50%;
}

.red {
    background-color: #ff605c;
}

.yellow {
    background-color: #ffbd44;
}

.green {
    background-color: #00ca4e;
}

@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Smooch+Sans:wght@100..900&family=Spicy+Rice&display=swap');
.spicy-rice-regular {
    font-family: "Spicy Rice", serif;
    font-weight: 400;
    font-style: normal;
}



.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 400;
    font-style: normal;
}

#cat-pic {
    transition: 0.1s;
    cursor: pointer;
}

#cat-pic:hover {
    border: 2px solid #6e6d7a;
    cursor: pointer;
}

.cat-card {
    background-color: #e2dad1;
}
</style>