<template>
    <div class="cards-container">
        <div
            v-for="n in 4"
            :key="n-1"
            class="card-item"
            :class="{ 'expanded': activeIndex === n-1 }"
            @mouseover="activeIndex = n-1"
        >
            <slot :name="`card-${n-1}`"  @mouseover.stop></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExpandingCards',
    props: {
        defaultActiveIndex: {
            type: Number,
            default: 1
        },
        height: {
            type: [Number, String],
            default: 400
        },
        normalFlex: {
            type: [Number, String],
            default: 1
        },
        expandedFlex: {
            type: [Number, String],
            default: 3
        }
    },
    data() {
        return {
            activeIndex: this.defaultActiveIndex
        }
    }
}
</script>

<style scoped>
.cards-container {
    display: flex;
    gap: 10px;
}

.card-item {
    flex: v-bind('normalFlex');
    transition: flex 0.5s ease-in-out;
    height: v-bind('typeof height === "number" ? `${height}px` : height');
    filter: brightness(0.4);
}

.card-item.expanded {
    flex: v-bind('expandedFlex');
    filter: brightness(1);
}

@media (max-width: 768px) {
    .cards-container {
        display: block;
    }
    .card-item {
        flex: initial;
        height: auto;
        filter: brightness(1);
    }
}
</style>
