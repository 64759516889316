<template>
    <div class="">
        <!-- Success Alert -->
        <div
            v-if="showSuccess"
            class="alert alert-success alert-dismissible fade show"
            role="alert"
        >
            {{$t("message.contact.notification.success")}}
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                @click="showSuccess = false"
            ></button>
        </div>

        <!-- Warning Alert -->
        <div
            v-if="showWarning"
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
        >
            {{$t("message.contact.notification.failed")}}
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                @click="showWarning = false"
            ></button>
        </div>
        <div id="container" class="mt-5 container">
            <div class="title">
                <h1>{{ $t("message.contact.header") }}</h1>
                <div class="line"></div>
            </div>

            <form id="form" class="row d-flex flex-column align-items-center" @submit.prevent="sendEmail">
                <div class="col-8 col-sm-10 col-md-8 col-lg-6">
                    <div class="item">
                        <label for="name" class="form-label">{{ $t("message.contact.name_label") }}</label>
                        <input type="text" class="form-control" id="name" v-model="formData.name" required>
                    </div>
                    <div class="item">
                        <label for="email" class="form-label">{{ $t("message.contact.email_label") }}</label>
                        <input type="email" class="form-control" id="email" v-model="formData.email" required>
                    </div>
                    <div class="item">
                        <label for="subject" class="form-label">{{ $t("message.contact.title_label") }}</label>
                        <input type="text" class="form-control" id="title" v-model="formData.title" required>
                    </div>
                    <div class="item">
                        <label for="message" class="form-label">{{ $t("message.contact.message_label") }}</label>
                        <textarea class="form-control" id="message" rows="5" v-model="formData.message" required></textarea>
                    </div>


                    <button class="submit-spinner btn" type="button" disabled v-if="isSending">
                        <span class="spinner-border spinner-border-sm spinner" aria-hidden="true"></span>
                        <span class="visually-hidden" role="status">Loading...</span>
                    </button>
                    <button class="submit-btn btn" type="submit" v-else>{{ $t("message.contact.send_btn") }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import emailjs from '@emailjs/browser';

export default {
    data() {
        return {
            formData: {
                name: '',
                email: '',
                title: '',
                message: ''
            },
            showSuccess: false,
            showWarning: false,
            isSending: false

        };
    },
    mounted() {
        emailjs.init("Bbu94PNlJ6zyFtwL6");
    },
    methods: {
        sendEmail() {
            this.isSending=true
            const serviceID = 'service_so9udlr';
            const templateID = 'template_v1ijpqg';
            const templateParams = {
                name: this.formData.name,
                email: this.formData.email,
                title: this.formData.title,
                message: this.formData.message
            };
            emailjs.send(serviceID, templateID, templateParams)
                .then(() => {
                    this.isSending=false
                    this.showSuccess = true;
                    this.showWarning = false; // 确保错误信息不显示
                    this.formData= {
                        name: '',
                        email: '',
                        title: '',
                        message: ''
                    }
                }, () => {
                    this.isSending=false
                    this.showWarning = true;
                    this.showSuccess = false; // 确保成功信息不显示
                });
        }
    }
}
</script>

<style lang="css" scoped>
.title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title .line{
    width: 50%;
    height: 5px;
    background-color: #FF6666;
    margin-bottom: 20px;
    
}

.item{
    margin-block: 20px;
}

.item label{
    font-weight: bold;
}

.submit-btn{
    width: 100%;
    background-color: white;
    color: #FF6666;
    border: 1px solid #FF6666;
}

.spinner{
    color: #FF6666;
}

.submit-spinner{
    border: 1px solid #FF6666;
    width: 100%;
    background-color: white;
}

.submit-btn:hover {
    width: 100%;
    background-color: #FF6666;
    color: white;
    text-align: center;
}
</style>