<template>
    <div id="Bottom-Footer" class="m-0 p-0">
        <div class="line_container m-0 p-0"><div class="line"></div></div>

        <div class="container">
            <contact-card></contact-card>
        </div>


        
        <div class="d-flex justify-content-center mt-5">
            <img src="@/assets/sign_zh.png" class="img-fluid" style="width: 100px">
            <img src="@/assets/sign_en.png" class="img-fluid" style="width: 100px">
        </div>
        <div class="row justify-content-center text-center m-0 p-0 mb-4">
            <p>粤ICP备2023082055号-1</p>
        </div>
    </div>

    
</template>

<script>
    import ContactCard from "@/components/ContactCard.vue";

    export default {
        components: {ContactCard},

    }
</script>

<style lang="css" scoped>



</style>