<template>
  <div class="position-relative card-container my-2 w-100 h-100 bg-white overflow-hidden rounded-4 " :class="isShadow? 'shadow': ''" @mouseenter="showOverlay = true" @mouseleave="showOverlay = false">

    <router-link :to="toURL" class="text-decoration-none" style="cursor: pointer">
        <slot></slot> <!-- 默认插槽，允许外部传入内容 -->
      <div v-show="isOverlay&&showOverlay&&(overlayStyle===1)" class="overlay rounded-4 border-0 h-100 overflow-auto wow fadeIn" data-wow-duration="1.5s">
          <h4>{{ description }}</h4>
      </div>
        <div v-show="isOverlay&&showOverlay&&(overlayStyle===2)"
             class="position-absolute bottom-0 start-0 w-100 bg-dark bg-opacity-50 text-white p-1
             rounded-bottom-4  wow fadeInUp" data-wow-duration="1.5s">
            <h4 class="p-1 text-center">{{ description }}</h4>
        </div>
    </router-link>


  </div>
</template>

<script>


export default {
  props:{
    toURL: {
      type: String,
      required: true
    },
      description:{
        type: String,
          required: false,
          default: ''
      },
    isShadow: {
      type: Boolean,
      required: false,
      default: false
    },
      isOverlay: {
          type: Boolean,
          required: false,
          default: true
      },
      overlayStyle: {
          type: Number,
          required: false,
          default: 1
      }
  },
  data() {
    return {
      showOverlay: false,
    };
  },
};
</script>

<style lang="css" scoped>
.card-container {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 65, 85, 0.9);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-inline: 10px;
  opacity: 0; /* 初始透明度为0 */
}

.overlay.fadeIn {
  opacity: 1; /* 显示时透明度为1 */
}

/* 隐藏滚动条 */
.overlay::-webkit-scrollbar {
  display: none; /* 对于WebKit浏览器 */
}

.overlay {
  -ms-overflow-style: none;  /* IE和Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
