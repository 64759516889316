<template>
    <div id="SingleStack" class="d-flex justify-content-center py-3 rounded-3 h-100" :style="`background-color: ${bgc};`">
        <div class="row  px-4 m-0">
            <div class="col-2 col-md-3 col-xl-2 d-flex justify-content-center align-items-center p-0 m-0">
                <img loading="lazy" :src="imgSrc" class="img-fluid" >
            </div>
            <div class="col-10 col-md-9 d-flex justify-content-center align-items-center p-0 m-auto"><h5 class="ps-2 fw-bold text-white m-0 text-responsive">{{stackName}}</h5></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        stackName:String,
        imgSrc:String,
        bgc:String
    }
};
</script>


<style lang="css" scoped>
.text-responsive {  
  font-size: calc(1rem); /* 使用vw单位实现响应式 */
}  
  
@media (min-width: 576px) {  
  .text-responsive {  
    font-size: 0.8rem; /* 小屏幕上的固定大小 */
  }  
}  
  
@media (min-width: 768px) {  
  .text-responsive {  
    font-size: 1rem; /* 中等屏幕上的固定大小 */
  }  
}
</style>