<template>
    <div class="mt-5 container-lg">
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-12 p-0 m-0">
                <!--标题卡片-->
                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <div class="d-flex align-items-center">
                                <div class="d-none d-md-block me-5 border border-1 rounded-5 shadow-sm p-3">
                                    <img loading="lazy" src="@/assets/worksDetail/whatToMix/icon.png" class="img-fluid rounded-3" style="width: 60px; height: 60px;"/>
                                </div>

                                <div>
                                    <h1 class="fw-bold">{{ $t('message.project.whatToMix.app_name') }}</h1>
                                    <a class="text-muted text-decoration-underline" href="https://terenzzzz.cn/whatToMix/">{{ $t('message.project.whatToMix.title') }}</a>
                                </div>
                            </div>
                            <div class="d-none d-md-flex flex-wrap mt-3">
                                <div v-for="(word,index) in ['VUE.JS','Bootstrap']" :key="index" class="d-flex mb-2">
                                    <TagButton :tag="word" class="mx-1"></TagButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/WhatToMix_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>
                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom fw-bold">{{$t('message.project.whatToMix.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.whatToMix.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>Vue3; Bootstrap</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.api_testing')}}</td>
                                    <td>Postman</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>WebStorm;</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!--系统功能卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="text-center red-bottom fw-bold">{{$t('message.project.whatToMix.feature.header')}}</h3>
                    <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- 查询功能 -->
                                <h5 class="fw-bold">{{ $t('message.project.whatToMix.feature.query_functionality.title') }}</h5>
                                <ul>
                                    <li>{{ $t('message.project.whatToMix.feature.query_functionality.cocktail_search_by_name') }}</li>
                                    <li>{{ $t('message.project.whatToMix.feature.query_functionality.ingredient_search_by_name') }}</li>
                                </ul>

                                <!-- 配方展示 -->
                                <h5 class="fw-bold">{{ $t('message.project.whatToMix.feature.recipe_display.title') }}</h5>
                                <ul>
                                    <li>{{ $t('message.project.whatToMix.feature.recipe_display.ingredient_list') }}</li>
                                    <li>{{ $t('message.project.whatToMix.feature.recipe_display.preparation_steps') }}</li>
                                    <li>{{ $t('message.project.whatToMix.feature.recipe_display.glassware_used') }}</li>
                                    <li>{{ $t('message.project.whatToMix.feature.recipe_display.basic_cocktail_info') }}</li>
                                </ul>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- 材料匹配推荐 -->
                                <h5 class="fw-bold">{{ $t('message.project.whatToMix.feature.ingredient_matching.title') }}</h5>
                                <ul>
                                    <li>{{ $t('message.project.whatToMix.feature.ingredient_matching.description') }}</li>
                                </ul>

                                <!-- 杯型筛选 -->
                                <!-- 材料匹配推荐 -->
                                <h5 class="fw-bold">{{ $t('message.project.whatToMix.feature.glass_type_filter.title') }}</h5>
                                <ul>
                                    <li>{{ $t('message.project.whatToMix.feature.glass_type_filter.description') }}</li>
                                </ul>


                                <!-- 类别筛选 -->
                                <h5 class="fw-bold">{{ $t('message.project.whatToMix.feature.category_filter.title') }}</h5>
                                <ul>
                                    <li>{{ $t('message.project.whatToMix.feature.category_filter.description') }}</li>
                                </ul>

                                <!-- 是否带酒精筛选 -->
                                <h5 class="fw-bold">{{ $t('message.project.whatToMix.feature.alcohol_content_filter.title') }}</h5>
                                <ul>
                                    <li>{{ $t('message.project.whatToMix.feature.alcohol_content_filter.description') }}</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <!--用户界面卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class=" mt-3">
                        <h3 class="red-bottom fw-bold">{{$t('message.project.coreFeatures')}}</h3>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/dashboard_matting.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.dashboard.title') }}</h3>
                            <p>{{ $t('message.project.whatToMix.feature.dashboard.description') }}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.ingredient_matching.title') }}</h3>
                            <p>{{ $t('message.project.whatToMix.feature.ingredient_matching.description') }}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/mixMatch_matting.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/search_matting.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.whatToMix.feature.query_functionality.title')}}</h3>
                            <ul>
                                <li>{{ $t('message.project.whatToMix.feature.query_functionality.cocktail_search_by_name') }}</li>
                                <li>{{ $t('message.project.whatToMix.feature.query_functionality.ingredient_search_by_name') }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.glass_type_filter.title') }}</h3>
                            <p>{{ $t('message.project.whatToMix.feature.glass_type_filter.description') }}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/glass_matting.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/category_matting.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.category_filter.title') }}</h3>
                            <p>{{ $t('message.project.whatToMix.feature.category_filter.description') }}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.alcohol_content_filter.title') }}</h3>
                            <p>{{ $t('message.project.whatToMix.feature.alcohol_content_filter.description') }}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/alcoholic_matting.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/recipe_matting.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.recipe_display.title') }}</h3>
                            <ul class="text">
                                <li>{{ $t('message.project.whatToMix.feature.recipe_display.ingredient_list') }}</li>
                                <li>{{ $t('message.project.whatToMix.feature.recipe_display.preparation_steps') }}</li>
                                <li>{{ $t('message.project.whatToMix.feature.recipe_display.glassware_used') }}</li>
                                <li>{{ $t('message.project.whatToMix.feature.recipe_display.basic_cocktail_info') }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{ $t('message.project.whatToMix.feature.ingredient_display.title') }}</h3>
                            <p>{{ $t('message.project.whatToMix.feature.ingredient_display.description') }}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img loading="lazy" src="@/assets/worksDetail/whatToMix/ingredient_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
       
</template>

<script>
import TagButton from "@/components/TagButton.vue";

export default {
    components: {
        TagButton,
    },

};
</script>

<style lang="css" scoped>
.red-bottom {
    width: fit-content;
    border-bottom: 3px solid #FF6666;
}

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

.comment-list {
    column-count: 2; /* 将列表分成两列 */
    column-gap: 20px; /* 列之间的间距，可以根据需要调整 */
    padding-left: 0; /* 移除左侧内边距 */
    list-style: none; /* 去除默认的列表样式 */
}

.comment-list li {
    margin-bottom: 10px; /* 每个列表项的下边距，可以根据需要调整 */
}
</style>