<template>
    <div class="mt-3">
       <WorkHeader title="基于Swing开发的租房系统" date="2023/07/15"/>
        <div class="row d-flex justify-content-center">
            <div class="col-10 col-lg-8">
                <div class="d-flex justify-content-center">
                    <img loading="lazy" src="@/assets/worksDetail/rentSystem/rentSystem.png" class="img-fluid w-100">
                </div>
                
                <table class="my-5">
                    <tr>
                        <th>#</th>
                        <th>类型</th>
                        <th>技术栈</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>编程语言</td>
                        <td>Java</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>前端</td>
                        <td>Swing</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>数据库</td>
                        <td>Mysql</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>源代码编辑器</td>
                        <td>VScode</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>版本控制</td>
                        <td>GitHub</td>
                    </tr>
                </table>

                <p class="fw-bold">开发一个租房系统</p>
                <p>这个项目的目标是创建一个软件系统，以满足下列组织的需要。该系统将使用Java实现，包括MySQL数据库和Java Swing用户界面。在您的分析过程中，您将开发许多UML模型，这些模型将构成您报告的一部分。数据库和用户界面的设计应该直接遵循这些模型。系统应该能够执行所要求的功能。该系统将支持不同的用户角色具有不同的访问权限，并且能够抵御明显的网络攻击。</p>
                <p>您的客户是HomeBreaks Plc，该公司管理一系列小型物业，供房东短期出租给租房客人。该软件系统将捕获有关酒店、主人和客人的详细信息，并将管理酒店的预订和评论。安全方面的问题是，根据业务规则，个人的联系方式和房产的精确位置不会透露给随便的询问者，而只会透露给授权用户。</p>
                <p>当主机第一次想要提供一个属性时，它会向系统进行自我注册。当客人第一次想预订酒店时，他们会自行登记。在注册时，他们提供他们的公共主人或客人名称，他们的保密人员联系方式和他们的保密地址。他们的电子邮件地址作为用户ID，并选择一个安全的密码。登录详细信息属于个人，但用户总是以其主机或来宾角色登录。询问者未经认证。</p>
                <p>房产提供流程如下。主机登录其主机角色。它们创建一个包含所有公共信息的新属性条目，并提供该属性的机密地址。然后，系统依次向主人展示每个设施(睡觉、洗澡、厨房、生活、公用设施、户外)，并要求他们检查提供哪些设施。对于睡眠和洗浴设施，主人还必须设计一些卧室(通常是一到两间)和浴室(通常是一间)，并检查那里提供了什么设施。</p>
                <p>预订流程如下。最初，查询器搜索有关属性和主机的公共信息，并在开始日期和结束日期之间的给定位置中选择属性。这将返回一组书签，其中书签与一个属性相关，描述开始和结束日期，并派生该属性在请求期间是否可用。查询者可以查看书签列表，并更详细地检查每个属性、主机和设施(仅限公共信息)。</p>
                <p>当准备好预订时，来宾登录到他们的来宾角色。他们要求预订在开始日期和结束日期之间的房产。只要酒店在这些日期内可用，这将创建一个临时预订，稍后将由主人接受(确认)或拒绝(拒绝)。每次预订都是由一位客人预订的(尽管同一位客人可能有许多其他预订，同一家酒店也可能有许多其他预订)。</p>
                <p>主人和客人都可以在主屏幕上看到预订列表。房东会看到他们每个酒店的预订列表。客人看到他们所要求的预订列表(在许多房产上)。房东可以选择接受或拒绝临时预订，但不能接受同一酒店的重复预订。客人必须登录查看他们的临时预订是否被接受或拒绝。我们假设预订在其结束日期之前保持可见，然后自动删除。</p>
                <p>对于每次预订，都可以推导出:住宿天数、每晚价格、服务费、保洁费和总费用(住宿费用、服务费和保洁费的总和)。一旦预订被主人接受，客人和主人将能够看到对方的保密联系信息。我们假定客人直接与主人联系以支付住宿费。客人还可以看到酒店的准确地址。所有保密资料均可由双方查阅，直至逗留结束</p>
                <p>入住后，客人提交一份评论。每条评论都是一位客人针对一家酒店写的，虽然酒店可能会收到很多评论，客人也可能会收到很多评论。每条评论都包含了对客人满意程度的文字描述，并给出了1到5分的评分(从低到高)在六个方面得分:清洁、沟通(反应时间)、登记(容易程度)、准确性(公共信息)，位置(质量)和价值(金钱)。对于每个属性，都可以推导出在所有提交的评论中，每个类别的平均分数，以及总体平均评分。一个如果房东的房产平均评分保持在4.7分，那么他们就成为了超级房东。</p>

            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';

export default {
    components: {
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

.subTitle{
    font-weight: bold;
}

img{
    max-height: 500px;
    object-fit: cover;
}
</style>