<template>
    <div class="mt-5 container-lg">
       <WorkHeader :title="$t('message.project.meowID.title')" date="2024/08/24"/>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-12 p-0 m-0">
                <!--标题卡片-->
                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <div class="d-flex align-items-center">
                                <div class="d-none d-md-block me-5 border border-1 rounded-5 shadow-sm p-3">
                                    <img loading="lazy" src="@/assets/worksDetail/meowID/icon.png" class="img-fluid rounded-3" style="width: 80px; height: 80px;"/>
                                </div>

                                <div>
                                    <h1 class="fw-bold">{{ $t('message.project.meowID.app_name') }}</h1>
                                    <a class="text-muted text-decoration-underline" href="https://terenzzzz.cn/meowid/">{{ $t('message.project.meowID.title') }}</a>
                                </div>
                            </div>
                            <div class="d-none d-md-flex flex-wrap mt-3">
                                <div v-for="(word,index) in ['Python','Pytorch', 'Machine Learning', 'VUE.JS', 'BOOTSTRAP', 'Flask']" :key="index" class="d-flex mb-2">
                                    <TagButton :tag="word" class="mx-1"></TagButton>
                                </div>
                            </div>

                        </div>
                        <div class="col-6">
                            <img loading="lazy" src="@/assets/worksDetail/meowID/screenshot.png" class="img-fluid">
                        </div>
                    </div>
                </div>
                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom fw-bold">{{$t('message.project.meowID.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.meowID.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>Vue3; Bootstrap</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.back_end')}}</td>
                                    <td>Flask; PyTorch</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.api_testing')}}</td>
                                    <td>Postman</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>WebStorm; VS Code; Spyder</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

<!--                核心技术卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">

                    <div class="row">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center ">
                            <h3 class="text-center red-bottom fw-bold">{{ $t('message.project.meowID.feature.coreTech.header') }}</h3>
                            <ul class="mt-3">
                                <li v-for="(content, index) in $t('message.project.meowID.feature.coreTech.content')" :key="index">{{ content }}</li>
                            </ul>
                        </div>

                    </div>
                </div>


<!--                模型结果-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">

                    <div class="row">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center ">
                            <h3 class="text-center red-bottom fw-bold">{{ $t('message.project.meowID.feature.trainResult.header') }}</h3>
                            <table class="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>{{ $t('message.project.meowID.feature.trainResult.trainLoss') }}</th>
                                    <th>{{ $t('message.project.meowID.feature.trainResult.trainAccuracy') }}</th>
                                    <th>{{ $t('message.project.meowID.feature.trainResult.testLoss') }}</th>
                                    <th>{{ $t('message.project.meowID.feature.trainResult.testAccuracy') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{ trainLoss }}</td>
                                    <td>{{ trainAccuracy }}</td>
                                    <td>{{ testLoss }}</td>
                                    <td>{{ testAccuracy }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">

                    <div class="row">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center ">
                            <h3 class="text-center red-bottom fw-bold">{{ $t('message.project.meowID.feature.resultEachClass.header') }}</h3>
                            <table class="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>{{ $t('message.project.meowID.feature.resultEachClass.EnglishClassName') }}</th>
                                    <th>{{ $t('message.project.meowID.feature.resultEachClass.ChineseClassName') }}</th>
                                    <th>{{ $t('message.project.meowID.feature.resultEachClass.accuracy') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(cat, index) in cats" :key="index">
                                    <td>{{ cat.name }}</td>
                                    <td>{{ cat.name_zh }}</td>
                                    <td>{{ cat.accuracy }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <!--                材料来源-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">

                    <div class="row">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center ">
                            <h3 class="text-center red-bottom fw-bold">{{ $t('message.project.meowID.feature.source.header') }}</h3>
                            <ul class="mt-3 w-100">
                                <li>{{ $t('message.project.meowID.feature.source.datasetSource.header') }}：<a href="https://github.com/Aml-Hassan-Abd-El-hamid" style="color: #007bff; text-decoration: underline; font-weight: bold;">{{ $t('message.project.meowID.feature.source.datasetSource.content') }}</a></li>
                                <li>{{ $t('message.project.meowID.feature.source.frontEndRepo.header') }}：<a href="https://github.com/terenzzzz/MeowID" style="color: #007bff; text-decoration: underline; font-weight: bold;">{{ $t('message.project.meowID.feature.source.frontEndRepo.content') }}</a></li>
                                <li>{{ $t('message.project.meowID.feature.source.backEndRepo.header') }}：<a href="https://github.com/terenzzzz/MeowIDAPI" style="color: #007bff; text-decoration: underline; font-weight: bold;">{{ $t('message.project.meowID.feature.source.backEndRepo.content') }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!--Promo卡片-->
            <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                <img loading="lazy" src="@/assets/worksDetail/meowID/landing.png" class="img-fluid w-100">
                <img loading="lazy" src="@/assets/worksDetail/meowID/result.png" class="img-fluid w-100">
            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import TagButton from "@/components/TagButton.vue";

export default {
    components: {
        TagButton,
        WorkHeader,
    },
    data() {
        return {
            trainLoss: '0.4772',
            trainAccuracy: '88.38%',
            testLoss: '1.2168',
            testAccuracy: '64.17%',
            cats: [
                {name: 'Abyssinian', name_zh: '阿比西尼亚猫', accuracy: '93.55%'},
                {name: 'American Curl', name_zh: '美国卷耳猫', accuracy: '15.15%'},
                {name: 'American Shorthair', name_zh: '美国短毛猫', accuracy: '22.58%'},
                {name: 'Balinese', name_zh: '巴厘猫', accuracy: '24.56%'},
                {name: 'Bengal', name_zh: '孟加拉猫', accuracy: '90.32%'},
                {name: 'Birman', name_zh: '缅因猫', accuracy: '87.10%'},
                {name: 'Bombay', name_zh: '孟买猫', accuracy: '92.47%'},
                {name: 'British Shorthair', name_zh: '英国短毛猫', accuracy: '84.95%'},
                {name: 'Burmese', name_zh: '缅甸猫', accuracy: '18.33%'},
                {name: 'Cornish Rex', name_zh: '康沃尔雷克斯猫', accuracy: '48.72%'},
                {name: 'Devon Rex', name_zh: '德文雷克斯猫', accuracy: '45.95%'},
                {name: 'Egyptian Mau', name_zh: '埃及猫', accuracy: '87.78%'},
                {name: 'Exotic Shorthair', name_zh: '异国短毛猫', accuracy: '34.69%'},
                {name: 'Extra-Toes Cat - Hemingway Polydactyl', name_zh: '海明威多趾猫', accuracy: '28.79%'},
                {name: 'Havana', name_zh: '哈瓦那猫', accuracy: '25.00%'},
                {name: 'Himalayan', name_zh: '喜马拉雅猫', accuracy: '50.59%'},
                {name: 'Japanese Bobtail', name_zh: '日本短尾猫', accuracy: '32.26%'},
                {name: 'Korat', name_zh: '暹罗猫', accuracy: '4.17%'},
                {name: 'Maine Coon', name_zh: '缅因库恩猫', accuracy: '89.25%'},
                {name: 'Manx', name_zh: '曼岛猫', accuracy: '30.61%'},
                {name: 'Nebelung', name_zh: '尼贝龙猫', accuracy: '51.35%'},
                {name: 'Norwegian Forest Cat', name_zh: '挪威森林猫', accuracy: '41.67%'},
                {name: 'Oriental Short Hair', name_zh: '东方短毛猫', accuracy: '21.62%'},
                {name: 'Persian', name_zh: '波斯猫', accuracy: '93.55%'},
                {name: 'Ragdoll', name_zh: '布偶猫', accuracy: '76.92%'},
                {name: 'Russian Blue', name_zh: '俄罗斯蓝猫', accuracy: '93.48%'},
                {name: 'Scottish Fold', name_zh: '苏格兰折耳猫', accuracy: '72.60%'},
                {name: 'Selkirk Rex', name_zh: '塞尔基雷克斯猫', accuracy: '29.17%'},
                {name: 'Siamese', name_zh: '暹罗猫', accuracy: '92.31%'},
                {name: 'Siberian', name_zh: '西伯利亚猫', accuracy: '10.81%'},
                {name: 'Snowshoe', name_zh: '雪鞋猫', accuracy: '71.76%'},
                {name: 'Sphynx', name_zh: '无毛猫', accuracy: '100.00%'},
                {name: 'Tonkinese', name_zh: '东南亚猫', accuracy: '22.03%'},
                {name: 'Toyger tiger cat', name_zh: '玩具虎猫', accuracy: '58.24%'},
                {name: 'Turkish Angora', name_zh: '土耳其安哥拉猫', accuracy: '80.49%'}
            ]
        };
    }
};
</script>

<style lang="css" scoped>
.red-bottom {
    width: fit-content;
    border-bottom: 3px solid #FF6666;
}

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

.comment-list {
    column-count: 2; /* 将列表分成两列 */
    column-gap: 20px; /* 列之间的间距，可以根据需要调整 */
    padding-left: 0; /* 移除左侧内边距 */
    list-style: none; /* 去除默认的列表样式 */
}

.comment-list li {
    margin-bottom: 10px; /* 每个列表项的下边距，可以根据需要调整 */
}
</style>